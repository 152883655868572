/*
  Footer JS
*/
$(function () {
	OLEDUI.Footer = function () {
		// toggle button
		const toggleBtn = $(".footer-nav button.toggle");
		toggleBtn.on("click", function (e) {
			e.preventDefault();

			const $this = $(this);
			const expand = $this.parent().hasClass("active");
			const alt = $this.data("alt");
			$this.parent().toggleClass("active");
			$this.attr("aria-expanded", !expand);
			if (expand) {
				//  closed
				$this.find(".sr-only").text(alt.expand);
			} else {
				// opened
				$this.find(".sr-only").text(alt.collapse);
			}
		});

		// language - mobile
		const $langAreaMobile = $(".footer.footer .lang.mobile-only");
		$langAreaMobile.on("click", function() {
			event.preventDefault();
			$langAreaMobile.toggleClass("is-active");
			var linkURL = $(event.target).attr('href');
			if(linkURL && linkURL.indexOf('#') == -1) {
				if(getCookie('test') != "!!") location.href = linkURL;
				pushDataLayer({
					'event': 'click_nav',
					'click_nav' : {
						'navCategory': 'footer',
						'navDepth1' : 'languages',
						'navDepth2' : convertTitle($(event.target).text()),
						'clickURL': $(event.target).attr('href'),
					}
				});
			}
		});

		// language - desktop
		const $langAreaD = $(".footer.footer .lang.desktop-only");
		const $langArea = $(".footer.footer .lang.desktop-only > ul");
		const $firstSNS = $("footer.footer .footer-social ul li:first-child a");
		$langAreaD.on("focus mouseenter touchstart", function () {
			if (!window.matchMedia("(max-width: 750px)").matches) {
				$langArea.addClass("expand");
			}
		});
		$langAreaD.on("mouseleave", function () {
			$langArea.removeClass("expand");
		});
		$firstSNS.on("focus", function () {
			$langArea.removeClass("expand");
		});
		$langArea.find("li a").on("click", function (event) {
			event.preventDefault();
			if ($langArea.hasClass("expand")) {
				if(getCookie('test') != "!!") window.location.href = $(this).attr("href");
				pushDataLayer({
					'event': 'click_nav',
					'click_nav' : {
						'navCategory': 'footer',
						'navDepth1' : 'languages',
						'navDepth2' : convertTitle($(this).text()),
						'clickURL': $(this).attr('href'),
					}
				});
			} else {
				if (window.matchMedia("(max-width: 750px)").matches) {
					$langArea.addClass("expand");
				} else {
					return false;
				}
			}
		});

		// click links
		const $linkDepth1 = $(".footer.footer .depth1 > a");
		$linkDepth1.on('click', function() {
			pushDataLayer({
				'event': 'click_nav',
				'click_nav' : {
					'navCategory': 'footer',
					'navDepth1' : convertTitle($(this).text()),
					'navDepth2' : '',
					'clickURL' : $(this).attr('href') == '#' ? '' : $(this).attr('href')
				}
			});
		});
		const $linkDepth2 = $(".footer.footer .depth2 a");
		$linkDepth2.on('click', function() {
			pushDataLayer({
				'event': 'click_nav',
				'click_nav' : {
					'navCategory': 'footer',
					'navDepth1' : convertTitle($(this).closest('.depth1').find('> span').text()),
					'navDepth2' : convertTitle($(this).text()),
					'clickURL' : $(this).attr('href') == '#' ? '' : $(this).attr('href')
				}
			});
		});

		// social 
		const $linkSocial = $(".footer.footer .footer-social a");
		$linkSocial.on('click', function() {
			pushDataLayer({
				'event': 'click_social_media',
				'click_social_media' : {
					'socialMediaName' : convertTitle($(this).parent().attr('class')),
					'clickURL' : $(this).attr('href') == '#' ? '' : $(this).attr('href')
				}
			});
		});

		// etc links
		const $linkEtc = $(".footer.footer .footer-links a");
		$linkEtc.on('click', function() {
			pushDataLayer({
				'event': 'click_nav',
				'click_nav' : {
					'navCategory': 'footer',
					'navDepth1' : convertTitle($(this).text()),
					'navDepth2' : '',
					'clickURL' : $(this).attr('href') == '#' ? '' : $(this).attr('href')
				}
			});
		});


		// top button
		const topArea = OLEDUI.layout.footer.find(".top");
		const topButton = topArea.find("button");
		topButton.on("click", function (e) {
			e.preventDefault();
			if ($(".search-layer").hasClass("active")) {
				$(".search-layer").stop().animate({ scrollTop: 0 }, 500);
				$(".search-layer").find(".btn-layer-close").focus();
			} else {
				$("html, body").stop().animate({ scrollTop: 0 }, 500);
				if (OLEDUI.layout.header.length > 0) {
					OLEDUI.layout.header.find(".logo a").focus();
				}
			}
		});
		let lastScroll = 0;
		$(window).on("scroll.top", function () {
			const st = $(window).scrollTop();
			if (st === 0 && !$(".search-layer").hasClass("active")) topArea.removeClass("show");
			else if (lastScroll > st) topArea.addClass("show");
			else if (lastScroll < st) topArea.removeClass("show");
			lastScroll = st;
		});
	};
	OLEDUI.Footer();
});
